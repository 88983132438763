.error{
    color:#F44335;
    font-size: 12px;
}
.padding-left-none {
    padding-left: 0 !important;
}

.padding-left-none:focus-visible{
    outline: none !important;

}

.list {
    padding: 10px;
    background: #f7f7f7;
    list-style: none;
    max-width: 300px;
    margin: auto;
  }
  
  .list-item {
    padding: 10px;
    margin: 5px 0;
    background: white;
    border: 1px solid #ddd;
    cursor: grab;
  }